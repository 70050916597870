import React from "react";
import "./HomeAbout.css";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import ButtonMore from "./ButtonMore";

const transition = { ease: [0.6, 0.01, -0.05, 0.9] };

function HomeAbout() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });
  return (
    <>
      <div className="about-container">
        <motion.h1
          ref={ref}
          animate={{
            letterSpacing: inView ? "1rem" : "10rem",
            opacity: inView ? 1 : 0,
            transition: { duration: 1.5, ...transition },
          }}
        >
          ABOUT ME
        </motion.h1>
        <motion.div
          className="text-container"
          ref={ref}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 50,
            transition: { duration: 1.5, ...transition },
          }}
        >
          <motion.p
          /* ref={ref}
            animate={{
              lineHeight: inView ? "1.5rem" : "50rem",
              transition: { duration: 1.5, ...transition },
            }}*/
          >
            Proin commodo fringilla neque, tincidunt faucibus leo egestas
            rutrum. Nulla eget velit sed odio ullamcorper elementum vitae vitae
            sem. Interdum et malesuada fames ac ante ipsum primis in faucibus.
            Nam porta tincidunt tortor, sed molestie ante suscipit at.
            Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Phasellus dapibus non arcu ut rhoncus.
            Proin suscipit nisi vel suscipit convallis. Morbi suscipit justo in
            ante scelerisque, sed convallis magna pretium.
          </motion.p>
          <ButtonMore />
        </motion.div>
      </div>
    </>
  );
}

export default HomeAbout;
