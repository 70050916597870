import React from "react";
import "./ButtonMore.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const transition = { duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] };

function ButtonMore() {
  return (
    <motion.div
      whileHover={{
        scale: 1.2,
        backgroundColor: "#f0f0f0",
        transition: { ...transition },
      }}
      className="button-more"
    >
      <Link to={"/about"}>
        <p>MORE</p>
      </Link>
    </motion.div>
  );
}

export default ButtonMore;
