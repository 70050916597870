import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import luci from "../images/luci_capuz.png";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };

const firstName = {
  animate: {
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

const lastName = {
  animate: {
    transition: {
      delayChildren: 1,
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

const letter = {
  initial: {
    y: -400,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 1, ...transition },
  },
};

const letterB = {
  initial: {
    y: 400,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: { duration: 1, ...transition },
  },
};

function HeroSection() {
  const [canScroll, setCanScroll] = useState(false);
  useEffect(() => {
    if (canScroll === false) {
      document.querySelector("body").classList.add("no-scroll");
    } else {
      document.querySelector("body").classList.remove("no-scroll");
    }
  }, [canScroll]);

  const { scrollYProgress } = useViewportScroll();
  //const scale = useTransform(scrollYProgress, [0, 1], [1, 1.2]);
  const bgposition = useTransform(scrollYProgress, [0, 1], [1, 5.5]);
  const marginleft = useTransform(scrollYProgress, [0, 1], [0, 500]);
  const marginright = useTransform(scrollYProgress, [0, 1], [0, -500]);

  return (
    <motion.div
      onAnimationComplete={() => setCanScroll(true)}
      initial="initial"
      animate="animate"
      exit="exit"
      className="hero-container"
    >
      <div className="hero-bg-mask">
        <motion.div style={{ scale: bgposition }} className="hero-bg" />
      </div>
      <motion.div
        style={{ marginLeft: marginright }}
        className="luci-first-name"
      >
        <motion.div variants={firstName} className="first-name">
          <motion.div variants={letter}>L</motion.div>
          <motion.div variants={letter} className="name-hifen"></motion.div>
          <motion.div variants={letter}>U</motion.div>
          <motion.div variants={letter}>C</motion.div>
          <motion.div variants={letter}>I</motion.div>
        </motion.div>
      </motion.div>
      <motion.div style={{ marginLeft: marginleft }} className="luci-last-name">
        <motion.div variants={lastName} className="last-name">
          <motion.div variants={letterB}>H</motion.div>
          <motion.div variants={letterB}>I</motion.div>
          <motion.div variants={letterB}>D</motion.div>
          <motion.div variants={letterB}>A</motion.div>
          <motion.div variants={letterB}>K</motion.div>
          <motion.div variants={letterB} className="name-hifen"></motion.div>
          <motion.div variants={letterB}>A</motion.div>
        </motion.div>
      </motion.div>

      <div className="hero-img-container">
        <motion.img
          style={{ y: marginleft }}
          initial={{
            opacity: 0,
            filter: "grayscale(100%)",
            x: 200,
          }}
          animate={{
            opacity: 1,
            filter: "grayscale(0%)",
            x: 0,
          }}
          transition={{
            delay: 0.5,
            duration: 3,
            ease: [0.6, 0.01, -0.05, 0.9],
          }}
          className="hero-img"
          src={luci}
          alt="Luci Hidaka"
        />
      </div>
    </motion.div>
  );
}

export default HeroSection;
