import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const transition = { ease: [0.6, 0.01, -0.05, 0.9] };

function CardItem(props) {
  var complete = false;

  const img = useRef(null);

  const [ref, inView, entry] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  function onComplete() {
    complete = true;
  }

  useEffect(() => {
    if (inView) {
      /* Store the element in el */
      let el = entry.target;

      /* Get the height and width of the element */
      const height = el.clientHeight;
      const width = el.clientWidth;

      /*
       * Add a listener for mousemove event
       * Which will trigger function 'handleMove'
       * On mousemove
       */
      el.addEventListener("mousemove", handleMove);

      /* Define function a */
      function handleMove(e) {
        if (complete) {
          /*
           * Get position of mouse cursor
           * With respect to the element
           * On mouseover
           */
          /* Store the x position */
          const xVal = e.clientX;
          /* Store the y position */
          const yVal = e.clientY - 200;

          /*
           * Calculate rotation valuee along the Y-axis
           * Here the multiplier 20 is to
           * Control the rotation
           * You can change the value and see the results
           */
          const yRotation = 10 * ((xVal - width / 2) / width);

          /* Calculate the rotation along the X-axis */
          const xRotation = -10 * ((yVal - height / 2) / height);

          /* Generate string for CSS transform property */
          const string =
            "perspective(500px) scale(1.1) rotateX(" +
            xRotation +
            "deg) rotateY(" +
            yRotation +
            "deg)";

          /* Apply the calculated transformation */
          el.style.transform = string;
        }
      }

      /* Add listener for mouseout event, remove the rotation */
      el.addEventListener("mouseout", function () {
        el.style.transform =
          "perspective(500px) scale(1) rotateX(0) rotateY(0)";
      });

      /* Add listener for mousedown event, to simulate click */
      el.addEventListener("mousedown", function () {
        el.style.transform =
          "perspective(500px) scale(0.9) rotateX(0) rotateY(0)";
      });

      /* Add listener for mouseup, simulate release of mouse click */
      el.addEventListener("mouseup", function () {
        el.style.transform =
          "perspective(500px) scale(1.1) rotateX(0) rotateY(0)";
      });
    }
  }, [inView]);

  return (
    <>
      <motion.li
        className="cards-item"
        ref={ref}
        animate={{
          opacity: inView ? 1 : 0,
          y: inView ? 0 : 50,
          transition: { duration: 1.5, ...transition },
        }}
        onAnimationComplete={onComplete}
      >
        <div ref={img} className="img-mask">
          <figure className="cards-item-pic-wrap">
            <Link className="cards-item-link" to={props.path}>
              <img src={props.src} alt="cards" className="cards-item-img" />
            </Link>
          </figure>
        </div>
        <div className="cards-item-info">
          <h3 className="cards-item-number">{props.number}</h3>
          <hr />
          <h2 className="cards-item-title">{props.title}</h2>
          <hr />
          <p className="cards-item-tag">{props.tag}</p>
        </div>
      </motion.li>
    </>
  );
}

export default CardItem;
