import React from "react";
import CardItem from "./CardItem";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import "./Cards.css";
import bpoolimg from "../images/bpool.jpg";
import ParticleComponent from "./ParticleComponent";

const transition = { ease: [0.6, 0.01, -0.05, 0.9] };

function Cards() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px 0px",
  });

  return (
    <div className="cards">
      <motion.h1
        ref={ref}
        animate={{
          letterSpacing: inView ? "1rem" : "10rem",
          opacity: inView ? 1 : 0,
          transition: { duration: 1.5, ...transition },
        }}
      >
        SELECTED WORKS
      </motion.h1>
      <div className="cards-container">
        <div className="cards-wrapper">
          <ul className="cards-itens">
            <CardItem
              src={bpoolimg}
              number="01."
              title="BPOOL"
              tag="UI Design"
              path="/bpool"
            />
            <CardItem
              src={bpoolimg}
              number="02."
              title="PAU PEDRA JÓIAS"
              tag="Branding"
              path="/paupedrajoias"
            />
            <CardItem
              src={bpoolimg}
              number="03."
              title="HUBIT.AI"
              tag="Branding"
              path="/hubitai"
            />
          </ul>
        </div>
      </div>
      <ParticleComponent />
    </div>
  );
}

export default Cards;
